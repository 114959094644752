@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
$mat-navisk: (
  //50: #ede7f6,
  //100: #d1c4e9,
  //200: #b39ddb,
  //300: #9575cd,
  //400: #7e57c2,
  //500: #673ab7,
  //600: #5e35b1,
  //700: #512da8,
  //800: #4527a0,
  //900: #311b92,
  //A100: #b388ff,
  //A200: #7c4dff,
  //A400: #651fff,
  //A700: #6200ea,
  50: lighten($Bprimary, 45%),
  100: lighten($Bprimary, 40%),
  200: lighten($Bprimary, 35%),
  300: lighten($Bprimary, 30%),
  400: lighten($Bprimary, 25%),
  500: lighten($Bprimary, 20%),
  600: lighten($Bprimary, 15%),
  700: lighten($Bprimary, 10%),
  800: lighten($Bprimary, 5%),
  900: $Bprimary,
  A100: lighten($Bprimary, 40%),
  A200: lighten($Bprimary, 30%),
  A400: lighten($Bprimary, 20%),
  A700: lighten($Bprimary, 10%),
  contrast: (
    50: mat.get-color-from-palette(mat.$indigo-palette, '50-contrast'),
    100: mat.get-color-from-palette(mat.$indigo-palette, '100-contrast'),
    200: mat.get-color-from-palette(mat.$indigo-palette, '200-contrast'),
    300: mat.get-color-from-palette(mat.$indigo-palette, '300-contrast'),
    400: mat.get-color-from-palette(mat.$indigo-palette, '400-contrast'),
    500: mat.get-color-from-palette(mat.$indigo-palette, '500-contrast'),
    600: mat.get-color-from-palette(mat.$indigo-palette, '600-contrast'),
    700: mat.get-color-from-palette(mat.$indigo-palette, '700-contrast'),
    800: mat.get-color-from-palette(mat.$indigo-palette, '800-contrast'),
    900: mat.get-color-from-palette(mat.$indigo-palette, '900-contrast'),
    A100: mat.get-color-from-palette(mat.$indigo-palette, 'A100-contrast'),
    A200: mat.get-color-from-palette(mat.$indigo-palette, 'A200-contrast'),
    A400: mat.get-color-from-palette(mat.$indigo-palette, 'A400-contrast'),
    A700: mat.get-color-from-palette(mat.$indigo-palette, 'A700-contrast'),
  )
);
$sbmn-primary: mat.define-palette($mat-navisk);
$sbmn-accent: mat.define-palette($mat-navisk, A200, A100, A400);
$sbmn-theme: mat.define-light-theme($sbmn-primary, $sbmn-accent);
//@include angular-material-theme($sbmn-theme);
@include mat.legacy-core-theme($sbmn-theme);
@include mat.legacy-autocomplete-theme($sbmn-theme);
@include mat.legacy-input-theme($sbmn-theme);
@include mat.legacy-checkbox-theme($sbmn-theme);
@include mat.legacy-radio-theme($sbmn-theme);
@include mat.legacy-select-theme($sbmn-theme);
@include mat.legacy-option-theme($sbmn-theme);
@include mat.legacy-form-field-theme($sbmn-theme);
@include mat.legacy-slider-theme($sbmn-theme);
@include mat.datepicker-theme($sbmn-theme);
//@include mat-dialog-theme($sbmn-theme);
@mixin sat-datepicker-theme($sbmn-theme) {
  $primary: $sbmn-primary;
  $foreground: map-get($mat-navisk, A700);
  $background: map-get($mat-navisk, A100);
  $mat-datepicker-selected-today-box-shadow-width: 1px;
  $mat-datepicker-selected-fade-amount: 0.6;
  $mat-datepicker-today-fade-amount: 0.2;
  /*  :not(.mat-calendar-body-disabled):hover,
  .cdk-keyboard-focused .mat-calendar-body-active,
  .cdk-program-focused .mat-calendar-body-active {
    & > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected) {
      background-color: mat-color($background, hover);
    }
    & > .mat-calendar-body-semi-selected {
      background-color: red;
      color: mat-color($primary, default-contrast);
    }
  }*/
  .mat-datepicker-content {
    min-height: 320px;
    max-height: 354px;
    width: auto !important;
    display: flex;
    justify-content: center;
  }
  .mat-calendar {
    height: auto !important;
    padding: 0 10px;
  }
  .mat-calendar-header {
    padding: 0 8px;
  }
  td.mat-calendar-body-label {
    opacity: 0;

    &[colspan='7'] {
      display: none;
    }
  }
  .quick-select-sec,
  .calendar-button-sec {
    display: none;
  }
  .mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
    border-radius: 100% 0 0 100%;
    background-color: $Bwhite;
  }
  .mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
    border-radius: 0 100% 100% 0;
    background-color: $Bwhite;
  }
  .mat-calendar-body > tr .mat-calendar-cell-semi-selected ~ .mat-calendar-cell-semi-selected {
    border-radius: 0;
  }
  .mat-calendar-cell-semi-selected,
  .mat-calendar-body-begin-range,
  .mat-calendar-body-end-range {
    background-color: $Bgrey1 !important;
  }
  .mat-button,
  .mat-icon-button {
    background: $Bwhite;

    &[disabled] {
      color: $Bwhite;
    }
  }
}

@include sat-datepicker-theme($sbmn-theme);
form {
  font-size: 1.6rem !important;
  * {
    font-family: basicregular, Arial, sans-serif;
  }

  .Berror {
    position: absolute;
    bottom: -17px;
    left: 0;
    padding: 0;
    font-size: 0.75em;
  }
}

.mat-form-field {
  width: 100%;
}

.cdk-overlay-pane {
  width: auto !important;
  overflow: hidden !important;
  display: block;
  @include mq('tablet', 'max') {
    min-width: 82% !important;
  }
}

.mat-autocomplete-panel {
  width: calc(105% + 30px) !important;
  display: block;
  overflow-y: scroll !important;
  max-width: 200% !important;

  &.mat-autocomplete-hidden {
    display: none;
  }

  mat-option {
    background: $Bwhite;
    color: $Bgrey6;
    padding: 5px;
  }

  .mat-option:hover:not(.mat-option-disabled) {
    color: $Bblack;
  }
}

.mat-radio-group {
  width: 100%;
  margin-bottom: 20px;

  mat-radio-button {
    margin-right: 20px;
  }
}

body {
  .mat-input-element {
    line-height: 1.2;
  }

  .mat-option {
    font-size: 1.5rem;
    height: 30px;
    line-height: 30px;
    text-transform: uppercase;
    @include mq('tablet', 'max') {
      font-size: 1.7rem;
      height: 45px;
      line-height: 30px;
    }
  }

  .mat-select-panel {
    max-width: unset;
  }

  .cdk-overlay-pane {
    box-shadow: 1px 2px 10px $Bgrey2 !important;
    border-radius: 5px;
    @include mq('tablet', 'max') {
      transform: none !important;
      left: 2% !important;
      max-width: 96% !important;
    }
  }
}

.mat-option.mat-active,
.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: rgba($Bsecondary, 0.3);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 50px $Bwhite inset;
}

.selInputsGrey {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 50px $Bgrey05 inset;
  }
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

fieldset {
  border: 0;
  padding: 0;

  &.center {
    text-align: center;
  }
}

textarea {
  resize: none;
  border-radius: 5px;
  border: 1px solid $Bgrey2;
  padding: 5px;
  font-family: basicregular, Arial, sans-serif;
  font-size: 1.4rem;
  display: block;
}

.wcheck .mat-checkbox-frame {
  border-color: rgba($Bprimary-dark, 0.54);
}

.mat-checkbox-layout .mat-checkbox-label {
  white-space: normal;
  line-height: 1;
}

.readonly .mat-form-field-underline {
  background: $Btransparent;
}

%button {
  padding: 15px 40px;
  font-size: 1.5rem;
  font-family: basicregular, Arial, sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  transition: box-shadow 0.1s linear;
  line-height: 1;

  &[disabled] {
    //background: $Bgrey5;
    //color: $Bwhite;
    filter: saturate(0.2);
    cursor: not-allowed;
    border: 1px solid $Btransparent;

    &:focus,
    &:active,
    &:hover {
      border: 1px solid $Btransparent;
      box-shadow: none;
    }
  }

  &:focus,
  &:active,
  &:hover {
    text-decoration: none;
  }
}

.buttonblue {
  @extend %button;
  border: 1px solid $Bsecondary;
  background: $Bsecondary;
  color: $Bwhite;

  &:focus,
  &:active,
  &:hover,
  &.fsel {
    border: 1px solid $Bsecondary;
    background: $Bwhite;
    color: $Bsecondary;
  }
}

.buttonbluelr {
  @extend %button;
  border: 1px solid $Bsecondary-light;
  background: $Bsecondary-light;
  color: $Bblack;

  &:focus,
  &:active,
  &:hover,
  &.fsel {
    border: 1px solid $Bsecondary;
    //background: $Bwhite;
    //color: $Bprimary-light;
    box-shadow: 0 0 17px -1px inset $Bsecondary;
  }
}

.buttonwhite {
  @extend %button;
  border: 1px solid $Bprimary;
  background: $Bwhite;
  color: $Bprimary;

  &:focus,
  &:active,
  &:hover {
    border: 1px solid $Bwhite;
    background: $Bprimary;
    color: $Bwhite;
  }

  &[disabled]:hover {
    background: $Bgrey5;
  }
}

.buttontransparent {
  @extend %button;
  border: 1px solid $Bgrey5;
  background: $Btransparent;
  color: $Bgrey5;

  &:focus,
  &:active,
  &:hover {
    border: 1px solid $Bgrey1;
    color: $Bprimary;
    background: $Bgrey1;
  }
}

.buttontransparentb {
  @extend .buttontransparent;
  background: rgba($Bblack, 0.5);
  color: $Bwhite;
}

.buttonyellow {
  @extend %button;
  border: 1px solid $Bsecondary;
  background: $Bsecondary;
  color: $Bblack;

  &:focus,
  &:active,
  &:hover {
    border: 1px solid darken($Bsecondary, 20%);
    box-shadow: 0 0 17px -1px inset darken($Bsecondary, 20%);
  }
}

.buttonred {
  @extend %button;
  border: 1px solid $Berror-light;
  background: $Berror-light;
  color: $Bwhite;

  &:focus,
  &:active,
  &:hover {
    border: 1px solid $Borange;
    box-shadow: 0 0 17px -1px inset $Berror-dark;
    background: $Berror;
  }
}

.Bbutton {
  @extend %button;
  text-decoration: none;
}
